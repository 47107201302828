import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DrawerDisplayMixin extends Vue {
  displayDrawer = false

  toggleDisplayDrawer () {
    this.displayDrawer = !this.displayDrawer
  }
}
