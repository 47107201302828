import showdown from 'showdown'

export function renderMarkdown (input: string, openLinksInNewWindow = false, linkClass?: string) {
  let bindings: {type: string, regex: RegExp, replace: string}[] = []

  if (linkClass) {
    const classMap: Record<string, string> = {
      a: linkClass
    }

    bindings = Object.keys(classMap)
      .map(key => ({
        type: 'output',
        regex: new RegExp(`<${key}(.*)>`, 'g'),
        replace: `<${key} class="${classMap[key]}" $1>`
      }))
  }

  const converter = new showdown.Converter({
    underline: true,
    openLinksInNewWindow: openLinksInNewWindow,
    extensions: [...bindings]
  })
  return converter.makeHtml(input)
}
