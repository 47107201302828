
























































import { defineComponent, computed } from '@vue/composition-api'
import { renderMarkdown } from '@/helpers/markdown'
import useBrokerage from '@/use/useBrokerage'
import IconBase from '@/components/icon-library/icon-base.vue'
import AgentSupport from '@/components/icon-library/agent-support.vue'
export default defineComponent({
  name: 'Hint',
  components: { AgentSupport, IconBase },
  props: {
    hintData: {
      type: Array as () => string[],
      required: true,
      default: () => []
    }
  },
  setup (props) {
    const { brokerageEmail, brokeragePhone, brokerageName } = useBrokerage()
    const markDown = (input: string, openLinksInNewWindow = false, linkClass?: string) => {
      return renderMarkdown(input, openLinksInNewWindow, linkClass)
    }

    const computedHintData = computed(() => {
    // THIS IS A TEMPORARY WORKAROUND FOR https://github.com/briza-insurance/briza-api/issues/5357
      if (props.hintData[0] === 'Types of insurance' && window.location.href.includes('appalachian')) {
        return props.hintData.filter(hint => {
          return hint.includes('Types of insurance') || hint.includes('Business Owner’s Policy')
        })
      }
      // END HERE
      return props.hintData
    })

    return {
      markDown,
      computedHintData,
      brokeragePhone,
      brokerageName,
      brokerageEmail
    }
  }
})

